<template>
  <v-container fluid>
    <v-card>
    <snackbar refs="snackbar"></snackbar>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>General Authorization</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-tabs background-color="tabToolbar">
              <v-tab>Users</v-tab>
              <v-tab>Groups</v-tab>
              <!-- v-tab-items -->

              <!-- general -->
              <v-tab-item>
                <v-text-field
                  label="search"
                  v-model="searchUser"
                ></v-text-field>
                <v-data-table
                  hide-default-footer
                  :headers="userHeaders"
                  :items="users"
                  :search="searchUser"
                >
                  <template v-slot:item.name="props">
                    <v-btn
                      color="primary"
                      text
                      @click="getAuth(props.item.id)"
                      >{{ props.item.name }}</v-btn
                    >
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  hide-default-footer
                  :headers="userGroupHeaders"
                  :items="userGroups"
                >
                  <template v-slot:item.name="props">
                    <v-btn
                      color="primary"
                      text
                      @click="getGroupAuth(props.item)"
                      >{{ props.item.name }}</v-btn
                    >
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-col>

          <v-col cols="9">
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                  readonly
                  filled
                  outlined
                  dense
                  v-model="userName"
                  label="Selected User"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="search"
                  label="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  dense
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  :headers="headers"
                  :items="authData"
                  :search="search"
                >
                  <!-- read editor -->
                  <template v-slot:item.read="props">
                    <v-checkbox
                      :disabled="activeStatus == false"
                      v-model="props.item.read"
                    ></v-checkbox>
                  </template>
                  <!-- end -->

                  <!-- write -->
                  <template v-slot:item.write="props">
                    <v-checkbox
                      :disabled="activeStatus == false"
                      v-model="props.item.write"
                    ></v-checkbox>
                  </template>
                  <!-- end -->

                  <!-- write -->
                  <template v-slot:item.update="props">
                    <v-checkbox
                      :disabled="activeStatus == false"
                      v-model="props.item.update"
                    ></v-checkbox>
                  </template>
                  <!-- end -->

                  <!-- groups -->
                  <template v-slot:item.groups="props">
                    <v-btn
                      text
                      color="primary"
                      @click="groupDialog(props.item.ObjectID)"
                      >Group Rights</v-btn
                    >
                  </template>
                  <!-- end -->
                </v-data-table>
              </v-col>

              <v-col cols="6">
                <v-btn
                  color="accent"
                  small
                  :disabled="activeStatus == false"
                  :loading="loading"
                  @click="updateUserAuth"
                >
                  <v-icon left>mdi-content-save</v-icon>Update
                </v-btn>
              </v-col>

              <v-col cols="3">
                <v-btn
                  color="accent"
                  small
                  :disabled="activeStatus == false"
                  @click="removefullAuth"
                >
                  <v-icon left>mdi-close</v-icon>Remove All
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="accent"
                  small
                  :disabled="activeStatus == false"
                  @click="fullAuth"
                >
                  <v-icon left>mdi-check</v-icon>Full Authorization
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- start of auth dialog -->
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dense color="modalToolbar" dark>
              <v-toolbar-title
                >{{ activeGroup.name }} - Authorization Setup</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col cols="7">
                    <v-data-table
                      :items-per-page="itemsPerPage"
                      hide-default-footer
                      :headers="headers"
                      :items="groupAuthData"
                      :search="search"
                    >
                      <!-- read editor -->
                      <template v-slot:item.read="props">
                        <v-checkbox v-model="props.item.read"></v-checkbox>
                      </template>
                      <!-- end -->

                      <!-- write -->
                      <template v-slot:item.write="props">
                        <v-checkbox v-model="props.item.write"></v-checkbox>
                      </template>
                      <!-- end -->

                      <!-- write -->
                      <template v-slot:item.update="props">
                        <v-checkbox v-model="props.item.update"></v-checkbox>
                      </template>
                      <!-- end -->
                    </v-data-table>
                  </v-col>

                  <v-col cols="8">
                    <v-btn
                      color="accent"
                      :loading="loader"
                      @click="updateGroupAuth"
                      >Update</v-btn
                    >
                  </v-col>
                  <v-col cols="4">
                    <v-btn color="accent" @click="groupFullAuth">
                      <v-icon left>mdi-check</v-icon>Full Authorization
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of auth dialog -->

        <!-- start of group dialog -->
        <v-dialog v-model="groupModal" max-width="600px">
          <v-card>
            <v-toolbar dense color="modalToolbar" dark>
              <v-toolbar-title>User Group Rights</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="groupModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12>
                  <v-data-table
                    hide-default-footer
                    :headers="groupHeaders"
                    :items="groupRights"
                  >
                    <!-- read editor -->
                    <template v-slot:item.read="props">
                      <v-checkbox
                        disabled
                        v-model="props.item.read"
                      ></v-checkbox>
                    </template>
                    <!-- end -->

                    <!-- write -->
                    <template v-slot:item.write="props">
                      <v-checkbox
                        disabled
                        v-model="props.item.write"
                      ></v-checkbox>
                    </template>
                    <!-- end -->

                    <!-- write -->
                    <template v-slot:item.update="props">
                      <v-checkbox
                        disabled
                        v-model="props.item.update"
                      ></v-checkbox>
                    </template>
                    <!-- end -->
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of group dialog -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: null,
    itemsPerPage: 100,
    dialog: false,
    searchUser: null,
    groupModal: false,
    loading: false,
    loader: false,
    trueValue: true,
    userName: null,
    userGroups: [],
    menuItems: [],
    users: [],
    items: [],
    allItems: [],
    authData: [],
    groupAuthData: [],
    activeStatus: false,
    activeUser: null,
    activeGroup: {},
    groupRights: [],
    authorizations: [
      { name: "Full Authorization" },
      { name: "Readonly" },
      { name: "No Authorization" },
    ],
    userHeaders: [
      { text: "Name", value: "name" },
      // { text: "Action", value: "action" },
    ],
    headers: [
      { text: "Subject", value: "DocumentName" },
      { text: "Read", value: "read" },
      { text: "Write", value: "write" },
      { text: "Update", value: "update" },
      { text: "Action", value: "groups" },
    ],
    groupHeaders: [
      { text: "Group", value: "name" },
      { text: "Read", value: "read" },
      { text: "Write", value: "write" },
      { text: "Update", value: "update" },
    ],
    userGroupHeaders: [{ text: "Group", value: "name" }],
  }),
  methods: {
    fullAuth() {
      for (const auth of this.authData) {
        auth.read = true;
        auth.write = true;
        auth.update = true;
      }
    },

    removefullAuth() {
      for (const auth of this.authData) {
        auth.read = false;
        auth.write = false;
        auth.update = false;
      }
    },
    groupFullAuth() {
      for (const auth of this.groupAuthData) {
        auth.read = true;
        auth.write = true;
        auth.update = true;
      }
    },
    groupDialog(objectId) {
      // get group rights
      const self = this;
      const id = this.activeUser;
      this.$store
        .dispatch("get", `/users/${id}/${objectId}`)
        .then((res) => {
          self.groupRights = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      // end of group rights
      // this.groupRights = groups;
      this.groupModal = true;
    },
    updateUserAuth() {
      const self = this;
      const data = {
        id: this.activeUser,
        documents: this.authData,
      };
      const url = "/authorization/assign-permission-to-user";
      self.loading = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    updateGroupAuth() {
      const self = this;
      const data = {
        id: this.activeGroup.id,
        documents: this.groupAuthData,
      };
      const url = "/authorization/assign-permission-to-role";
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          self.loader = false;
          location.reload();
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          console.log(err, "err");
          self.$refs.snackbar.show(err, "red");
        });
    },
    userAuth(id) {
      const self = this;
      self.loader = true;
      self.activeUser = id;
      this.$store
        .dispatch("get", `/users/${id}`)
        .then((res) => {
          self.userName = res.ResponseData.name;
          self.authData = res.ResponseData.documents;
          self.activeStatus = true;
          self.dialog = true;
          self.loader = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGroupAuth(data) {
      this.activeGroup = data;
      // get group permissions
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/usergroup/${data.id}`)
        .then((res) => {
          self.loader = false;
          self.groupAuthData = res.documents;
          self.dialog = true;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      // get group permissions
    },
    getAuth(id) {
      const self = this;
      self.loader = true;
      self.activeUser = id;
      this.$store
        .dispatch("get", `/users/auth/${id}`)
        .then((res) => {
          self.userName = res.ResponseData.name;
          self.authData = res.ResponseData.documents;
          self.activeStatus = true;
          self.loader = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDocs() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/doc_model`)
        .then((res) => {
          self.authData = res;
          self.groupAuthData = res;
          self.loader = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getMenu() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/form_settings_menu`)
        .then((res) => {
          self.items = res.AllTreeview;
          self.allItems = res.AllIds;
          self.menuItems = res.VisibleIds;
          self.loader = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGroups() {
      const url = "/usergroup";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.loader = false;
          self.userGroups = res.ResponseData;
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getMenu();
    this.getUsers();
    this.getDocs();
    this.getGroups();
  },
};
</script>
